/**
 * Should this page be an "Internal" or super admin / dev page instead?
 */
import { lazy, Suspense } from 'react';

import { RoleName } from '@m/types/api/iam/Iam';
import { Switch } from 'react-router-dom';

import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';

const EnforcementContainer = lazy(
  () => import('apps/admin/containers/Enforcement/EnforcementContainer'),
);

export const enforcementRoutesConfig = [
  {
    exact: true,
    path: '/enforcement',
    breadcrumb: 'Enforcement',
    roles: [RoleName.MANAGER, RoleName.CUSTOMER_SUPPORT],
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <EnforcementContainer />
      </Suspense>
    ),
  },
];

export default function EnforcementRoutes() {
  return (
    <Switch>
      {enforcementRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
